<template>
  <div class="user">
    <div class="user_bg"></div>
    <div class="info">
      <img class="profile" src="../assets/default_profile.png" />
      <span class="uname">{{ userName }}</span>
    </div>
  </div>

  <van-cell-group inset style="margin-top: 40px">
    <van-cell title="账号" :value="account" />
    <van-cell title="修改密码" is-link @click="toResetPwd" />
    <van-cell title="关于" is-link @click="toAbout" />
  </van-cell-group>

  <div style="margin: 100px 30px">
    <van-button
      round
      block
      color="#ee0a24"
      plain
      native-type="submit"
      @click="onLogout"
    >
      退出账号
    </van-button>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { Cell, CellGroup } from "vant";
import { safeName } from '../common/js/utils';

export default {
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
  },
  setup() {
    const router = useRouter(); //传值
    const userName = ref("");
    const account = ref("");

    const toAbout = () => {
      router.push({
        path: "/about",
      });
    };
    const onLogout = () => {
      localStorage.clear();
      router.replace("/");
    };

    const toResetPwd = () => {
      router.push({
        path: "/resetpwd",
      });
    };

    onMounted(() => {
      /* eslint-disable */
      upsdk.pluginReady(function () {
        upsdk.setNavigationBarTitle({
          title: "我的",
        });
        upsdk.setTitleStyle({
          backBtnVisible: "0", 
          navBackgroundColor: "0xff1989fa",
        });
      });
      userName.value = safeName(localStorage.getItem("userName"));
      account.value =  mobileStr(localStorage.getItem("phone"));
    });
    
    //账号中间加*号 
     function mobileStr(str) {
        return str.substring(0, 3) + "****" + str.substring(7, str.length);  
    }

    return {
      onLogout,
      toResetPwd,
      toAbout,
      userName,
      account,
    };
  },
};
</script>


<style lang="less">
.user {
  position: relative;
  width: 100%;
  z-index: -1;
  height: 180px;
  overflow: hidden;

  .user_bg {
    position: absolute;
    width: 140%;
    height: 180px;
    left: -20%;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    background-image: linear-gradient(0deg, #1989fa, #1989fa 50%);
    z-index: -1;
  }

  .info {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    margin-top: 40px;

    .profile {
      display: block;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin: 0 auto;
    }
    .uname {
      display: block;
      width: 120px;
      margin-top: 10px;
      text-align: center;
      color: #fff;
      font-size: 14px;
    }
  }
}
</style>>